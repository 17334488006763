import "./App.css";
import PurchaseContainer from "./index.jsx";
import UpdatePurchse from "./newIndex.jsx";
import { useEffect, useState } from "react";
const os = require("os-browserify/browser");

function App() {
  const [isWellKnownPath, setIsWellKnownPath] = useState(false);
  const [jsonContent, setJsonContent] = useState(null);

  useEffect(() => {
    // Check if the URL path is for Android or iOS
    const path = window.location.pathname;
    if (
      path === "/.well-known/assetlinks.json" ||
      path === "/.well-known/apple-app-site-association"
    ) {
      setIsWellKnownPath(true);

      // Choose the appropriate JSON file based on URL path
      const jsonFile =
        path === "/.well-known/assetlinks.json"
          ? "/.well-known/assetlinks.json"
          : "/.well-known/apple-app-site-association";

      // Fetch and load the JSON content
      fetch(jsonFile)
        .then((response) => response.json())
        .then((data) => setJsonContent(data))
        .catch((error) => console.error("Failed to load JSON", error));
    }
  }, []);

  // Render the JSON content if we're on a well-known path
  if (isWellKnownPath) {
    return (
      <div className="App">
        <pre>{JSON.stringify(jsonContent, null, 2)}</pre>
      </div>
    );
  }
  return (
    <div className="App">
      {/* <PurchaseContainer /> */}
      <UpdatePurchse />
    </div>
  );
}

export default App;
