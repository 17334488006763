import { useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ReactComponent as UpArray } from "../assets/up_array.svg";

export default function Month({ setAddressData, AddressData }) {
  const people = [
    { id: "01", name: "January" },
    { id: "02", name: "February" },
    { id: "03", name: "March" },
    { id: "04", name: "April" },
    { id: "05", name: "May" },
    { id: "06", name: "June" },
    { id: "07", name: "July" },
    { id: "08", name: "August" },
    { id: "09", name: "September" },
    { id: "10", name: "October" },
    { id: "11", name: "November" },
    { id: "12", name: "December" },
  ];
  const [selectedPerson, setSelectedPerson] = useState(people[0]);

  useEffect(() => {
    setAddressData({ ...AddressData, month: people[0].id });
  }, []);

  return (
    <div className="md:w-[50%] w-[45%]">
      <Listbox
        value={selectedPerson}
        onChange={(data) => {
          setSelectedPerson(data);
          setAddressData({ ...AddressData, month: data.id });
        }}
      >
        {({ open }) => (
          <div className="relative">
            <Listbox.Button
              className="relative w-full py-3 pl-2 md:pr-10 pr-7 outline-none text-left bg-white border border-gray-300 cursor-default"
              style={{ border: "1px solid #000000" }}
            >
              <span className="block truncate">{selectedPerson.name}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <UpArray
                  className={`md:w-5 md:h-5 w-3 h-3 text-gray-400 transform transition-transform ${
                    open ? "rotate-0" : "rotate-180"
                  }`}
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as="div"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {people.map((person) => (
                  <Listbox.Option
                    key={person.id}
                    className={({ active }) =>
                      `${
                        active ? "text-amber-900 bg-amber-100" : "text-gray-900"
                      }
                      cursor-default select-none relative p-3`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`${
                            selected ? "font-semibold" : "font-normal"
                          } block truncate`}
                        >
                          {person.name}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  );
}
