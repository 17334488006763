export const States = [
  { text: "Select State" },
  { text: "Andaman and Nicobar" },
  { text: "Andhra Pradesh" },
  { text: "Arunachal Pradesh" },
  { text: "Assam" },
  { text: "Bihar" },
  { text: "Chandigarh" },
  { text: "Chhattisgarh" },
  { text: "Dadra & Nagar Haveli" },
  { text: "Delhi" },
  { text: "Goa" },
  { text: "Gujarat" },
  { text: "Haryana" },
  { text: "Himachal Pradesh" },
  { text: "Jammu and Kashmir" },
  { text: "Jharkhand" },
  { text: "Karnataka" },
  { text: "Kerala" },
  { text: "Ladakh" },
  { text: "Lakshadweep(UT)" },
  { text: "Madhya Pradesh" },
  { text: "Maharashtra" },
  { text: "Manipur" },
  { text: "Meghalaya" },
  { text: "Mizoram" },
  { text: "Nagaland" },
  { text: "Odisha" },
  { text: "Pondicherry" },
  { text: "Punjab" },
  { text: "Rajasthan" },
  { text: "Sikkim" },
  { text: "Tamil Nadu" },
  { text: "Tripura" },
  { text: "UA-Uttarakhand" },
  { text: "UT of DNH and DD" },
  { text: "Uttarakhand" },
  { text: "Uttar Pradesh" },
  { text: "West Bengal" },
];
