import styled, { keyframes } from 'styled-components';
import { Button, TextField } from '@mui/material';
import { bounceInLeft } from 'react-animations';

const bounceAnimation = keyframes`${bounceInLeft}`;

export const MainWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  --webkit-backdrop-filter: blur(10px);
`;

export const Container = styled.div`
  width: 75%;
  max-width: 100%;
  padding: 10px;
  overflow: hidden;
  border-radius: 10px;
  margin: 20px auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  background: white;

  @media (max-width: 768px) {
    width: 90% !important;
    margin 20px auto;
  }
  @media (max-width: 600px) {
    width: 90% !important;
    margin 20px auto;
  }
`;

export const ContainerHeader = styled.div`
  margin: 0px auto;
  padding: 10px;
  width: 95%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  font-weight: 100;

  img {
    height: 30px;
    width: 30px;
    margin-right: 5px;
  }
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @media (max-width: 775px) {
    font-size: 0.8rem;
    @media (max-width: 550px) {
      h1 {
        font-size: 16px;
      }
    }
    @media (max-width: 360px) {
      h1 {
        font-size: 14px;
      }
    }
  }
`;
export const SerialWrapper = styled.div`
  ${'' /* width: 25px; */}
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 1em;
  }
  span {
    padding-right: 15px;
  }
  @media (max-width: 600px) {
    width: 100%;
    justify-content: space-between;
    span {
      padding-left: 10px;
    }
  }
`;

export const ContainerBody = styled.div`
  text-align: center;
  margin: auto;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  width: 95%;
  margin: 0px auto;
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
`;
export const FormFooterButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    padding-left: 15px;
    width: 100%;
    display: flex;
    justify-content: left;
  }
  @media (max-width: 600px) {
    div {
      padding-bottom: 30px;
    }
  }
`;
export const AddMemTitle = styled.div`
  width: 100%;
  color: black;
  text-align: left;
  padding-left: 20px;
  //padding-bottom: 50px;
  h2 {
    font-size: 1rem;
  }
`;

export const MasterWrapper = styled.div`
  width: 100%;
  p{
    text-align : left;
    font-size : 0.7rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  button {
    background-color: rgb(0, 0, 0, .1);
  }

  @media (max-width: 600px) {
    div {
      padding-bottom: 15px;
    }

    border-bottom: #d1a62a solid 1px;
    flex-direction: column;
    button {
      position: relative;
      bottom: 50px;
      left: 330px;
      font-size: 10px;
    }

    @media (max-width: 460px) {
      button {
        position: relative;
        left: 250px;
      }
    }
    @media (max-width: 370px) {
      button {
        left: 200px;
      }
    }

`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
 

  div > div {
    margin-right: 5px;
  }

  div:last-child > div {
    margin-right: 0;
    height: 100%;
  }

  @media (max-width: 600px) {
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: 10px;

    div > div {
      margin-bottom: 10px;
    }

    ${'' /* div:last-child > div {
      margin-left: 20px;
    } */}
    input {
      width: 80vw;
    }
    select {
      width: 85vw;
      margin-bottom : 30px;
    }
    option {
      margin: 10px;
      padding: 10px;
    }
  }
  //@media (max-width: 460px) {
  //  input:last-child {
  //    width: 70px;
  //    height: 10px;
  //  }

  //@media (max-width: 370px) {
  //  select {
  //    width: 100px;
  //  }

  //input:last-child {
  //  margin-left: 0px;
  //}
  //}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  //width: 30%;
  align-items: center;
  justify-content: center;
`;

export const EmailForm = styled.div`
  margin-bottom: 15px;
  color: #d2a614;
  animation: 1s ${bounceAnimation};
  h2 {
    font-weight: lighter;
  }
  @media (max-width: 600px) {
    h2 {
      font-size: 18px;
    }
  }
`;

export const OtpForm = styled.div`
  width: 80%;
  color: #d2a614;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: 1s ${bounceAnimation};
  input {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  @media (max-width: 600px) {
    .target input {
      width: 10vw !important;
      height: 10vw !important;
      font-size: 5vw !important;
    }
  }
`;

export const FamilyMembers = styled.div`
  color: #d2a614;
  animation: 1s ${bounceAnimation};
  width: 98%;
  margin: 0px auto;

  h2 {
    text-align: left;
  }
`;

// Components

export const FormButton = styled(Button)`
  && {
    width: 150px;
    padding: 10px 0;
    border-radius: 12px;
    background: linear-gradient(160deg, rgba(210, 167, 43, 1) 0%, rgba(163, 127, 23, 1) 83%);
    color: #fff;
  }
`;

export const InputForm = styled(TextField)`
  && {
    /* border: 1px solid black; */
    border-radius: 7px;
    // width: 240px;
    // height: 60px;
    font-size: 20px;
    color: #000;
    width: 80%;
    caret-color: #d2a614;
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

export const Select = styled.select`
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
  no-repeat 95% 50%;
  background-size: 20px;
  margin-right: 5px;
  max-height: 100% !important;
  min-height: 56px !important;
  width: 220px;
  padding-left: 10px;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  @media (max-width: 600px) {
    min-height: 50px !important;
  }
  @media (max-width: 460px) {
    width: 120px;
    min-height: 50px !important;
  }

  &:focus {
    outline: 1px soild #0047AB !important;
  }
`;
