import { useState, useRef } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ReactComponent as UpArray } from "../assets/up_array.svg";
import { States } from "./States";

export default function StateName({
  setAddressData,
  setBloodTestSchedule,
  BloodTestSchedule,
}) {
  const [SelectedState, setSelectedState] = useState(States[0]);

  const comboboxRef = useRef(null);

  const openDirection = () => {
    if (comboboxRef.current) {
      const comboboxRect = comboboxRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const spaceAbove = comboboxRect.top;
      const spaceBelow = windowHeight - comboboxRect.bottom;

      const threshold = 200;

      if (spaceBelow < threshold && spaceAbove > spaceBelow) {
        return "top";
      } else {
        return "bottom";
      }
    }
  };

  return (
    <div className="w-[100%]">
      <Listbox
        value={SelectedState}
        ref={comboboxRef}
        onChange={(data) => {
          setSelectedState(data);
          setAddressData((prevState) => ({
            ...prevState,
            address_details: {
              ...prevState.address_details,
              state: data.text,
            },
          }));
          setBloodTestSchedule({
            ...BloodTestSchedule,
            state: data.text,
          });
        }}
      >
        {({ open }) => (
          <div className="relative">
            <Listbox.Button
              className="relative w-full py-3 pl-5 md:pr-10 pr-7 outline-none text-left bg-white border border-gray-300 cursor-pointer"
              style={{ border: "1px solid #000000" }}
            >
              <span className="flex items-center truncate">
                {SelectedState.text}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <UpArray
                  className={`md:w-5 md:h-5 w-3 h-3 text-gray-400 transform transition-transform ${
                    open ? "rotate-0" : "rotate-180"
                  }`}
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as="div"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={`absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${
                  !openDirection() === "top" ? "top-[50px]" : "bottom-[60px]"
                }`}
              >
                {States.filter((person) => person.text !== "Select State").map(
                  (person) => (
                    <Listbox.Option
                      key={person.id}
                      className={({ active }) =>
                        `${
                          active
                            ? "text-amber-900 bg-amber-100"
                            : "text-gray-900"
                        }
                      cursor-default select-none relative p-3`
                      }
                      value={person}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`${
                              selected ? "font-semibold" : "font-normal"
                            } flex items-center truncate`}
                          >
                            {person.text}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  )
                )}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  );
}
