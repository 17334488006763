/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { RxCross2 } from "react-icons/rx";
import {
  ButtonWrapper,
  Container,
  ContainerBody,
  ContainerHeader,
  EmailForm,
  FamilyMembers,
  FieldsContainer,
  FieldWrapper,
  Form,
  FormButton,
  InputForm,
  MainWrapper,
  OtpForm,
  Wrapper,
  Select,
  SerialWrapper,
  AddMemTitle,
  FormFooterButtonWrapper,
  MasterWrapper,
} from "./styles";

import OtpInput from "react18-input-otp";

import { Button, duration, TextField } from "@mui/material";
import axios from "axios";
import { displayRazorpay } from "./purchaseUtils";
import { Checkbox } from "@mui/material";
import useWindowSize from "./use-window-size.js";
import logo from "./assets/Asset-11.png";

const axiosInstance = axios.create({
  // baseURL: "https://api.unlock.fit/", //prod
  baseURL: "https://dev-api.unlock.fit/", //dev
});

// for Email
let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

const PurchaseContainer = () => {
  console.log(process.env, "env");
  // axios.defaults.baseURL = process.env.NEXT_PUBLIC_BASEURL;
  // axios.defaults.baseURL = "https://dev-api.unlock.fit/";

  //form for development
  // const [otpEmail, setOtpEmail] = React.useState(false);
  // const [emailBox, setEmailBox] = React.useState(false);
  // const [showDependentForm, setDependentForm] = React.useState(true);

  const [otpEmail, setOtpEmail] = React.useState(true);
  const [emailBox, setEmailBox] = React.useState(true);
  const [showDependentForm, setDependentForm] = React.useState(false);

  const [showOtpForm, setOtpFrom] = React.useState(false);
  const [code, setCode] = useState(null);
  const [products, setProducts] = useState([]);
  const [userId, setUserId] = useState(null);
  const [userData, setUserData] = useState(null);
  const [purchasedMessage, setPurchasedMessage] = useState(false);
  const [exists, setExists] = useState(false);
  const { width } = useWindowSize();
  const [trigger, setTrigger] = useState(1);

  const otpHandeler = (code) => setCode(code);

  const [formValues, setFormValues] = React.useState([
    { name: "", email: "", plan: "", price: "", plan_id: "", add: false },
    { name: "", email: "", plan: "", price: "", plan_id: "", add: false },
  ]);

  useEffect(() => {
    console.log("useEffect", formValues);
  }, [formValues]);

  function isEmail(input) {
    // regular expression for validating email addresses
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // check if the input matches the email regex
    return emailRegex.test(input);
  }

  const getDurations = (id) => {
    let durations = [];
    products.forEach((item) => {
      if (item.package_id == id && item.has_durations) {
        durations = item.durations;
      }
    });
    return durations;
  };

  const hasDurations = (id) => {
    let hasDurations = false;
    products.forEach((item) => {
      if (item.package_id == id && item.has_durations) {
        hasDurations = true;
      }
    });
    return hasDurations;
  };

  const getDurrationPrice = (id, durations) => {
    let price = 0;
    durations.forEach((item) => {
      if (item.id == id) {
        price = item.special_price;
      }
    });
    return price;
  };

  const getDurationString = (id, durations) => {
    let durationString = "";
    durations.forEach((item) => {
      if (item.id == id) {
        durationString = item.life + " " + item.duration_type;
      }
    });
    return durationString;
  };

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    console.log(i, e.target.name, e.target.value, "change");
    if (e.target.name === "plan") {
      const eventObject = JSON.parse(e.target.value);
      console.log("newFormvalueof i", newFormValues[i]);
      newFormValues[i].plan_id = eventObject.id;
      newFormValues[i].price = hasDurations(eventObject.id)
        ? getDurations(eventObject.id)[0].special_price
        : eventObject.price;
      newFormValues[i].duration_id = hasDurations(eventObject.id)
        ? getDurations(eventObject.id)[0].id
        : null;
      newFormValues[i][e.target.name] = e.target.value;
      newFormValues[i].durations = getDurations(eventObject.id);
      newFormValues[i].has_durations = hasDurations(eventObject.id);
      console.log("newForm", newFormValues);
    } else if (e.target.name === "duration") {
      newFormValues[i].price = getDurrationPrice(
        e.target.value,
        newFormValues[i].durations
      );
      newFormValues[i]["duration_id"] = e.target.value;
    } else {
      newFormValues[i][e.target.name] = e.target.value;
      // if (e.target.name === 'email') {
      // if checkbox is checked
      if (e.target.name === "email") {
        newFormValues[i]["has_active_plan"] = false;
        newFormValues[i]["message"] = "";
      }
      if (e.target.name === "email" && isEmail(e.target.value)) {
        axiosInstance
          .post(`website/user/check_user_subscription`, {
            email: e.target.value,
          })
          .then((res) => {
            setTrigger(trigger + 1);
            console.log("res", res.data);
            newFormValues[i]["has_active_plan"] = res.data.has_active_plan;
            newFormValues[i]["message"] = res.data.message;
            setFormValues(newFormValues);
          })
          .catch((err) => {
            setTrigger(trigger + 1);
            console.log("err.response", err.response.data);
            newFormValues[i]["has_active_plan"] =
              err.response.data.has_active_plan;
            newFormValues[i]["message"] = err.response.data.message;
            setFormValues(newFormValues);
          });
      }
      if (e.target.name === "add") {
        if (e.target.checked) {
          newFormValues[i].add = "true";
        } else {
          newFormValues[i].add = "false";
        }
      }
    }

    console.log("newFormValues", newFormValues);
    setFormValues(newFormValues);

    let email = e.target.value;
    if (email !== "") {
      formValues.forEach((item, index) => {
        if (index !== i) {
          if (item.email === email) {
            console.log("email exists", item.email);
            setExists(true);
          } else {
            setExists(false);
          }
        }
      });
    }
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      { name: "", email: "", plan: "", price: "", plan_id: "", add: false },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  let SubmitDependent = () => {
    console.log("formValues", formValues);

    // create a variable which will take only values where add is true

    console.log("Final Data", formValues);

    let allpurchase;
    let familyArray = [];

    formValues.map((item, index) => {
      if (index !== 0) {
        if (item.add === "true") {
          familyArray.push(item);
        }
      }
    });

    formValues.forEach((item, index) => {
      if (index === 0) {
        if (item.add === "true") {
          allpurchase = 0;
        } else {
          allpurchase = 1;
        }
      }
      allpurchase = 1;
    });
    let newFamilyArray = [];
    familyArray.forEach((item) => {
      if (item.add === "true") {
        let obj = {};
        obj.name = item.name;
        obj.email = item.email;
        obj.plan_id = item.plan_id;
        if (item.duration_id) {
          obj.duration_id = item.duration_id;
        }
        obj.price = item.price;
        newFamilyArray.push(obj);
      }
    });
    familyArray = newFamilyArray;
    console.log("familyData", familyArray);
    // console.log('familyData', `${familyArray} | ${allpurchase}`);

    let totalPrice = 0;

    formValues.forEach((item) => {
      if (item.add === "true") {
        if (item.price !== "") {
          totalPrice += parseInt(item.price);
        }
      }
    });

    if (allpurchase === 0) {
      console.log("allpurchase form", allpurchase);
      if (formValues.some((item) => item.name === "")) {
        toast.error("Please fill all the fields");
      } else if (formValues.some((item) => item.email === "")) {
        toast.error("Please fill all the fields");
      } else if (formValues.some((item) => item.plan === "")) {
        toast.error("Please fill all the fields");
      } else if (formValues.some((item) => item.price === "")) {
        toast.error("Please fill all the fields");
      } else if (formValues.some((item) => item.plan_id === "")) {
        toast.error("Please fill all the fields");
      } else if (exists) {
        toast.error("You cannot use duplicate email");
      } else {
        displayRazorpay(
          totalPrice,
          userId,
          formValues,
          allpurchase,
          familyArray
        );
      }
    } else {
      if (formValues.slice(1).some((item) => item.name === "")) {
        toast.error("Please fill all the fields");
      } else if (formValues.slice(1).some((item) => item.email === "")) {
        toast.error("Please fill all the fields");
      } else if (formValues.slice(1).some((item) => item.plan === "")) {
        toast.error("Please fill all the fields");
      } else if (formValues.slice(1).some((item) => item.price === "")) {
        toast.error("Please fill all the fields");
      } else if (formValues.slice(1).some((item) => item.plan_id === "")) {
        toast.error("Please fill all the fields");
      } else if (exists) {
        toast.error("You cannot use duplicate email");
      } else {
        displayRazorpay(
          totalPrice,
          userId,
          formValues,
          allpurchase,
          familyArray
        );
      }
    }
  };

  // step 1. Take input of otp email and provides us with user id
  let changeEmailField = () => {
    if (regex.test(otpEmail)) {
      toast("Email is valid", { type: "success" });
      axiosInstance
        .post(`website/user/register`, {
          email: otpEmail,
        })
        .then(
          (res) => {
            console.log(res);

            // saves the user id in the state
            setUserId(res.data.data.userId);
            setEmailBox(false);
            // setOtpFrom(true);

            //step 3
            axiosInstance
              .get(
                `website/user/getUserSubcriptionPlan?userId=` +
                  res.data.data.userId
              )
              .then((res) => {
                console.log("User subscription package", res.data.data[0]);

                if (res.data.data[0] !== undefined) {
                  const _formValues = [...formValues];
                  _formValues[0].plan = res.data.data[0].package_title;
                  _formValues[0].plan_id = res.data.data[0].package_id;
                  _formValues[0].price = res.data.data[0].special_price;
                  _formValues[0].name = res.data.data[0].user_details.name;
                  if (res.data.data[0].duration_id) {
                    _formValues[0].duration_id = res.data.data[0].duration_id;
                    _formValues[0].has_durations = true;
                    _formValues[0].durations = getDurations(
                      res.data.data[0].package_id
                    );
                  }
                  setFormValues(_formValues);
                  setUserData(res);
                  // setPurchasedMessage(true);
                  setDependentForm(true);
                } else {
                  setUserData(null);
                  setOtpFrom(true);
                }
              });
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
      toast("Enter A Valid Email", { type: "error" });
    }
  };

  let OtpSubmitHandeller = () => {
    if (code.length === 6) {
      axiosInstance
        .post(`website/user/verify_user`, {
          userId: userId,
          token: code,
        })
        .then((res) => {
          console.log(res);
          if (res.data.result === "success") {
            toast("OTP Verified", { type: "success" });
            setOtpFrom(false);
            setDependentForm(true);
          } else {
            toast("OTP Verification Failed", { type: "error" });
          }
        });
    } else {
      toast("Enter a valid OTP", { type: "error" });
    }
  };

  let handelEmailFor = (event) => {
    setOtpEmail(event.target.value);
    formValues[0].email = event.target.value;
  };

  useEffect(() => {
    axiosInstance
      .get(`website/user/getPlanListV1`)
      .then((res) => {
        setProducts(res.data.data);
        console.log(products);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [showDependentForm]);

  const values = products?.map((product) => (
    // eslint-disable-next-line react/jsx-key
    <option
      value={`{"price":"${product.special_price}","id":"${product.package_id}"}`}
    >
      {product.package_title}
    </option>
  ));

  const OtpInputBoxStyle = {
    border: "1px solid black",
    borderRadius: "7px",
    width: "5vw",
    height: "5vw",
    fontSize: "2vw",
    color: "#000",
    fontWeight: "",
    caretColor: "#d2a614",
  };

  return (
    <>
      <div>
        <Toaster />
      </div>

      <MainWrapper>
        <Container>
          <ContainerHeader>
            <div>
              <img className={"logo"} src={logo} alt="logo1" />
              {/*<h1 className={'title'}>unlock.fit</h1>*/}
            </div>
            <div className={"Header-Title"}>
              <h1>Product Subscription</h1>
            </div>
          </ContainerHeader>
          <ContainerBody>
            <Form>
              {emailBox ? (
                <EmailForm>
                  <h2>Get Started By Entering Your Email</h2>
                  <InputForm
                    label="Email"
                    variant="outlined"
                    onChange={handelEmailFor}
                  />
                  <div>
                    <FormButton variant="contained" onClick={changeEmailField}>
                      Submit
                    </FormButton>
                  </div>
                </EmailForm>
              ) : null}

              {showOtpForm ? (
                <OtpForm>
                  <h2>Enter OTP From Your Email</h2>
                  <OtpInput
                    value={code}
                    // style={{justifyContent:'center'}}
                    onChange={otpHandeler}
                    numInputs={6}
                    className={"target"}
                    separator={<span style={{ width: "8px" }}></span>}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    inputStyle={OtpInputBoxStyle}
                    focusStyle={{
                      border: "1px solid blue",
                      outline: "none",
                    }}
                  />
                  <FormButton variant="contained" onClick={OtpSubmitHandeller}>
                    Submit
                  </FormButton>
                </OtpForm>
              ) : null}

              {purchasedMessage ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "300px",
                  }}
                >
                  <h3>You have already purchased product.</h3>
                  <p>
                    Click here to go to{" "}
                    <a href="https://www.unlock.fit/">Home</a>
                  </p>
                </div>
              ) : null}

              {showDependentForm ? (
                <FamilyMembers>
                  {formValues.map((element, index) => (
                    <FieldsContainer className="form-inline" key={index}>
                      {index ? null : (
                        <AddMemTitle>
                          <h2>My Details</h2>
                          <br></br>
                        </AddMemTitle>
                      )}
                      <MasterWrapper>
                        <Wrapper>
                          <SerialWrapper>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              {index ? (
                                <h1>{index}</h1>
                              ) : (
                                <div style={{ width: "1em" }}></div>
                              )}
                              <Checkbox
                                name="add"
                                onChange={(e) => handleChange(index, e)}
                                value={element.add ? "false" : "true"}
                                disabled={!!(userData && index === 0)}
                              />
                            </div>
                            <ButtonWrapper>
                              {index && width <= 600 ? (
                                <RxCross2
                                  style={{
                                    fontSize: "25px",
                                    margin: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => removeFormFields(index)}
                                />
                              ) : null}
                            </ButtonWrapper>
                          </SerialWrapper>
                          <FieldWrapper>
                            <TextField
                              label="Name"
                              variant="outlined"
                              type="text"
                              name="name"
                              value={element.name || ""}
                              onChange={(e) => handleChange(index, e)}
                              disabled={!!(userData && index === 0)}
                            />
                            <TextField
                              label="Email"
                              variant="outlined"
                              type="email"
                              name="email"
                              value={element.email || ""}
                              onChange={(e) => handleChange(index, e)}
                              disabled={index === 0}
                            />
                            {userData && index === 0 ? (
                              <TextField
                                label="Product"
                                variant="outlined"
                                name="plan"
                                type={"text"}
                                value={element.plan || ""}
                                onChange={(e) => handleChange(index, e)}
                                disabled={index === 0}
                              />
                            ) : (
                              <Select
                                label="Products"
                                variant="outlined"
                                name="plan"
                                value={element.plan || ""}
                                onChange={(e) => handleChange(index, e)}
                                disabled={!!(userData && index === 0)}
                              >
                                <option>Products</option>
                                {values}
                              </Select>
                            )}
                            {userData && index === 0 ? (
                              <TextField
                                label="Duration"
                                variant="outlined"
                                name="duration"
                                type={"text"}
                                value={
                                  element.has_durations
                                    ? getDurationString(
                                        element.duration_id,
                                        element.durations
                                      )
                                    : "No Duration"
                                }
                                onChange={(e) => handleChange(index, e)}
                                disabled={!element.has_durations}
                              />
                            ) : (
                              <Select
                                label="Duration"
                                variant="outlined"
                                name="duration"
                                value={element.duration_id || ""}
                                onChange={(e) => handleChange(index, e)}
                                disabled={!element.has_durations}
                              >
                                <option disabled={element.has_durations}>
                                  Duration
                                </option>
                                {element.durations?.map((duration) => (
                                  <option value={duration.id}>
                                    {duration.life +
                                      " " +
                                      duration.duration_type}
                                  </option>
                                ))}
                                }
                              </Select>
                            )}

                            <TextField
                              disable
                              value={`₹ ${element.price || "0"}`}
                              disabled={!!(userData && index === 0)}
                            />
                          </FieldWrapper>
                          {/* <img
                              className={'remove'}
                              src={logo}
                              alt="logo1"
                              width={'25px'}
                              style={{ cursor: 'pointer' }}
                              onClick={() => removeFormFields(index)}
                            /> */}
                          <ButtonWrapper>
                            {index && width > 600 ? (
                              <RxCross2
                                style={{
                                  fontSize: "25px",
                                  margin: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => removeFormFields(index)}
                              />
                            ) : !index && width > 600 ? (
                              <div style={{ width: "45px" }}></div>
                            ) : null}
                          </ButtonWrapper>
                        </Wrapper>
                        {index ? (
                          element.has_active_plan ? (
                            <p>{element.message}</p>
                          ) : null
                        ) : null}
                      </MasterWrapper>

                      {!index && userData !== null ? (
                        <AddMemTitle>
                          <br></br>
                          <h2
                            style={{
                              color: "red",
                              padding: "0px",
                              margin: "0px",
                              fontSize: "0.8rem ",
                            }}
                          >
                            You have already purchased a plan. Not sure ?{" "}
                            <a href="https://api.whatsapp.com/send/?phone=919818875990">
                              Contact us
                            </a>
                          </h2>
                        </AddMemTitle>
                      ) : null}

                      {index ? null : (
                        <AddMemTitle>
                          <br></br>
                          <h2>Add Family</h2>
                          <br></br>
                        </AddMemTitle>
                      )}
                    </FieldsContainer>
                  ))}
                  <FormFooterButtonWrapper>
                    <div>
                      <Button
                        className="button add"
                        type="button"
                        onClick={() => addFormFields()}
                      >
                        Add More Members
                      </Button>
                    </div>
                    <FormButton
                      className="buttonsubmit"
                      onClick={SubmitDependent}
                    >
                      Submit
                    </FormButton>
                  </FormFooterButtonWrapper>
                </FamilyMembers>
              ) : null}
            </Form>
            {exists ? (
              <h5 style={{ color: "red" }}>*You cannot use duplicate email</h5>
            ) : null}
          </ContainerBody>
        </Container>
      </MainWrapper>
    </>
  );
};
export default PurchaseContainer;
